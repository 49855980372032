<!--System: Whistle it
  module : Super Admin 
  Developer: Gulraiz Ahmed 
  Date: 17-06-2021 
  Organization: Programmers Force 
  Purpose: The purpose of this view is to display Super_Admin dashboard -->
<template>
  <dashboard />
</template>
<script>
import dashboard from "@/components/dashboard";
export default {
  components: {
    dashboard,
  },
};
</script>
