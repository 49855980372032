<!--System: Whistle it
  module : Super Admin 
  Developer: Gulraiz Ahmed 
  Date: 17-06-2021 
  Organization: Whistle it 
  Purpose: The purpose of this component is to display Super_Admin dashboard -->

<template>
  <v-app id="inspire">
    <v-app-bar color="white" app clipped-right flat height="60">
      <v-app-bar-nav-icon
        class="hidden-lg-and-up"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-toolbar-title
        ><strong>{{ active }}</strong></v-toolbar-title
      >
      <v-spacer></v-spacer>
      <v-responsive max-width="160"> </v-responsive>
    </v-app-bar>
    <!------------------------------------------Navigation drawer---------------------------------------------->
    <v-navigation-drawer color="#390c2e" v-model="drawer" app width="250">
      <v-sheet color="#390c2e" height="60" width="100%">
        <v-list-item two-line>
          <v-avatar color="#763BC4" size="36" class="mr-2">
            <span class="white--text text-body-2"
              >{{ fname[0] }}{{ lname[0] }}</span
            >
          </v-avatar>
          <v-list-item-content>
            <v-list-item-title class="white--text">{{
              full_name
            }}</v-list-item-title>
            <v-list-item-subtitle class="white--text">{{
              email
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-sheet>
      <br />
      <!-----------------------------------List of all Components----------------------------------------->
      <v-list>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          :to="item.path"
          @click="clickButton(item.topTitle)"
        >
          <v-list-item-icon>
            <v-icon class="white--text">{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="white--text">{{
              item.title
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <!--------------------------------------------Logout button--------------------------------------------->
      <v-btn
        color="#390c2e"
        width="180px"
        class="ml-8 white--text logout-btn"
        title
        elevation="0"
        @click="signoutBtn()"
        >Log out<v-img
          class="ml-n2"
          contain
          aspect-ratio="4"
          src="@/assets/icons/logout.svg"
        ></v-img>
      </v-btn>
    </v-navigation-drawer>
    <!-- start of snack -->
    <template>
      <div>
        <v-snackbar v-model="signoutErrorSnack" right top color="green">
          {{ signoutErrorSnackText }}
          <template v-slot:action="{ attrs }">
            <v-btn
              color="white"
              text
              v-bind="attrs"
              @click="signoutErrorSnack = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </div>
    </template>
    <!-- end of snack -->
    <Snackbar
      :snackbar.sync="snackbar"
      :message="message"
      :color="snackbarColor"
    />
    <v-main class="bgColor pa-0">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>
<!---------------------------------------------Script------------------------------------------------->
<script>
import { mapActions } from "vuex";
import Snackbar from "@/components/Snacbar";
import paymentSvg from "@/assets/images/payment-icon.svg";

export default {
  name: "dashboard",
  components: {
    // FormLayout,
    Snackbar,
  },
  data() {
    return {
      signoutErrorSnackText: "Your session has expired !!!",
      signoutErrorSnack: false,
      active: "SUPER ADMIN",
      drawer: null,
      snackbar: false,
      message: "",
      snackbarColor: "",
      data: {},
      fname: "",
      lname: "",
      full_name: "",
      email: "",
      items: [
        {
          title: "Dashboard",
          icon: "mdi-monitor-dashboard",
          path: "/dashboard",
          topTitle: "SUPER ADMIN",
        },
        {
          title: "Companies",
          icon: "mdi-office-building-marker-outline",
          path: "/companies",
          topTitle: "COMPANIES",
        },
        {
          title: "Users",
          icon: "mdi-account-outline",
          path: "/users",
          topTitle: "USERS",
        },
        {
          title: "Teams",
          icon: "mdi-account-group-outline",
          path: "/teams",
          topTitle: "TEAMS",
        },
        {
          title: "Channels",
          icon: "mdi-earth",
          path: "/channels",
          topTitle: "CHANNELS",
        },
        {
          title: "Admins",
          icon: "mdi-account-supervisor-circle",
          path: "/admins",
          topTitle: "Admins",
        },
        {
          title: "Logs",
          icon: "mdi-microsoft-xbox-controller-menu",
          path: "/logs",
          topTitle: "Logs",
        },
        {
          title: "Settings",
          icon: "mdi-cog",
          path: "/setting",
          topTitle: "Settings",
        },
        {
          title: "Payments",
          icon: "mdi-credit-card-outline",
          path: "/payments",
          topTitle: "Payments",
        },
      ],
    };
  },
  methods: {
    ...mapActions(["SignOutClear"]),
    clickButton(value) {
      this.active = value;
    },
    signoutBtn() {
      this.SignOutClear().then(
        () => {},
        (error) => {
          if (error.response.status == 401) {
            this.signoutErrorSnack = true;
            let vm = this;
            setTimeout(() => {
              vm.$router.push("/");
            }, 200);
          }
        }
      );
    },
    signout() {
      this.SignOutClear();
    },
  },
  beforeMount() {
    let tempData = localStorage.getItem("data");
    this.data = JSON.parse(tempData);
    this.full_name = this.data.full_name;
    this.email = this.data.email;
    let name = this.full_name.split(" ");
    if (name.length > 1) {
      this.fname = name[0];
      this.lname = name[1];
    } else {
      this.fname = name[0][0];
      this.lname = name[0][1];
    }
  },
};
</script>
<style lang="css" scoped>
.bgColor {
  background: #eceef2;
}
.logout-btn {
  position: fixed;
  bottom: 22px;
}
</style>
